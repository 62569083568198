import { FC, useRef } from 'react';
import type { TemplateFunction } from 'Components/types.ts';
import { useSelector } from 'react-redux';
import { useCollapsible } from 'Core/hooks/index.js';
import { searchResponseExtraSelector } from 'Core/selectors/search.js';
import { cloneSafe } from 'Utils/components.ts';
import { selectedVehicleSelector } from 'Core/selectors/fitmentSearch/index.js';
import isMobile from '../../vendor/isMobile';

interface WheelTireVehicleInfoExtra {
  WheelData: WheelData;
  TireSizes: string[];
}

interface WheelData {
  FrontAxle: WheelsAxleInfo;
  RearAxle: WheelsAxleInfo;
  BoltPattern: string;
  HubBore: string;
  Hardware: string;
  Thread: string;
  Hex: string;
}
interface WheelsAxleInfo {
  Diameter: string;
  Width: number;
  Offset: number;
}

interface Params {
  collapsed: boolean;
  toggleCollapsed: () => void;
  data: WheelTireVehicleInfoExtra;
  vehicleString: string;
}

interface Props {
  template: TemplateFunction<Params>;
  initCollapsed?: boolean;
}

const WheelTireVehicleInfo: FC<Props> = ({ template, initCollapsed }) => {
  const rootRef = useRef<HTMLElement>(null);
  const [collapsed, toggleCollapsed] = useCollapsible(rootRef, null, initCollapsed ?? isMobile);

  const selectedVehicle = useSelector(selectedVehicleSelector);

  const wheelTireVehicleInfo = useSelector(searchResponseExtraSelector)
    ?.WheelTireVehicleInfo as WheelTireVehicleInfoExtra;

  if (!wheelTireVehicleInfo) {
    return null;
  }

  const component = template.call({
    collapsed,
    toggleCollapsed,
    data: wheelTireVehicleInfo,
    vehicleString: selectedVehicle.toString(),
  });

  return cloneSafe(component, rootRef);
};

export default WheelTireVehicleInfo;
