import shopifyGenericDefaults from '../shopify-generic/config.js';

import {
  getVehicleString,
  updateVehicleVisualization,
  setStoreConfig,
} from '../_auto-sync/autosyncVisualizer.js';

import {
  onVehicleSelected as cartVerifyOnVehicleSelected,
  onVehicleDiscarded as cartVerifyOnVehicleDiscarded,
  AfterInit as cartVerifyAfterInit,
} from './cartPageVerifyFitment.js';

const { baseFields, extraFields } = shopifyGenericDefaults.fitmentSearch;

const wheelsFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarWheels').fields;
const tiresFields = shopifyGenericDefaults.Widgets.find((w) => w.name === 'FacetBarTires').fields;

const categorySelectionPageUrl = '/collections';
const brandSelectionPageUrl = '/pages/brands';

const getFitmentSearchTitle = () =>
  window.location.pathname === categorySelectionPageUrl
    ? 'Collections'
    : window.location.pathname === brandSelectionPageUrl
      ? 'Brands'
      : window.location.pathname.includes('/collections/tires')
        ? 'Tires'
        : window.location.pathname.includes('/collections/wheels')
          ? 'Wheels'
          : window.location.pathname.includes('/collections/accessories')
            ? 'Accessories'
            : 'Parts';

globalThis.Convermax.getVehicleUrlWithCategory = (fitmentType) => {
  const urlWithVehicle = window.Convermax.getVehicleSearchParams();
  if (fitmentType?.includes('Tire')) {
    return `/collections/tires?${urlWithVehicle}`;
  }
  if (fitmentType?.includes('Wheel')) {
    return `/collections/wheels?${urlWithVehicle}`;
  }
  return `${shopifyGenericDefaults.searchPageUrl}?${urlWithVehicle}`;
};

globalThis.Convermax.mountedPackagesAddToCartWithRedirect = (productId, variantId) => async (e) => {
  const { href, pathname } = window.location;

  const redirectPath =
    href.includes('WheelProductID') || href.includes('TireProductID')
      ? '/cart'
      : pathname === '/collections/packages'
        ? `/collections/tires/?WheelProductID/${productId}/${window.Convermax.getVehicleSearchParams()}`
        : null;

  if (redirectPath) {
    const quantityValue = e.target.querySelector('.cm_quantity').value;

    e.preventDefault();
    e.stopPropagation();

    const response = await ajaxAddToCart(variantId, +quantityValue);

    if (response.ok) {
      window.location.replace(redirectPath);
    } else {
      // check if cart contains product
      // need this case to force redirect when client has this porudct in cart
      const cartResponse = await ajaxGetCartItems();
      const parsedCardResponse = await cartResponse.json();

      if (parsedCardResponse?.items?.some((i) => i.variant_id === +variantId)) {
        window.location.replace(redirectPath);
      } else {
        console.error(response);
      }
    }
  }
};

async function ajaxAddToCart(id, quantity = 4) {
  const formData = {
    items: [
      {
        id,
        quantity,
      },
    ],
  };

  return fetch(`${window.Shopify.routes.root}cart/add.js`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formData),
  });
}

async function ajaxGetCartItems() {
  return fetch(`${window.Shopify.routes.root}cart.js`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

shopifyGenericDefaults.Widgets.find((w) => w.name === 'RelatedItems').count = 5;
const fieldsForRelated = ['category', ...wheelsFields, ...tiresFields];
function getSelection(itemData) {
  const selections = fieldsForRelated.flatMap((field) => {
    const value = itemData[field];
    return !value
      ? []
      : Array.isArray(value)
        ? value.map((term) => ({ field, term }))
        : { field, term: value };
  });
  selections.push({ field: 'inventory_quantity', term: '[4 TO *]' });
  return selections;
}

let searchResultsVehicleVisualizer = null;

window.Convermax.handleProductQtySelector = (productId, isPlus) => {
  const qtyInput = window.document.querySelector(`#input-${productId}`);
  const inputQuantity = window.document.querySelector(`.cm_add-to-cart-form-${productId} .cm_quantity`);

  if (qtyInput && inputQuantity) {
    if (isPlus) {
      qtyInput.value++;
    } else if (qtyInput.value > 1) {
      qtyInput.value--;
    }

    inputQuantity.value = qtyInput.value;
  }
};

const getItemId = () => {
  if (document.querySelector('#cm-verify-fitment.cm_ecom-variant')) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const variantId = urlParams.get('variant');

    if (variantId) {
      return variantId;
    }

    const shopifyAnalytics = window.ShopifyAnalytics;
    if (shopifyAnalytics && shopifyAnalytics.meta.product.variants.length > 0) {
      return shopifyAnalytics.meta.product.variants[0].id;
    }
  }

  const shopifyAnalytics = window.ShopifyAnalytics;
  if (shopifyAnalytics) {
    return `${shopifyAnalytics.meta.product.id}`;
  }
  console.warn('window.ShopifyAnalytics not found');
  return '';
};

const baseUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
)?.updateCallback;

function updateCallback() {
  baseUpdateCallback?.();

  const vehicleString = getVehicleString();
  if (!vehicleString) {
    return;
  }

  searchResultsVehicleVisualizer = updateVehicleVisualization({
    autoSyncVisualizer: searchResultsVehicleVisualizer,
    autoSyncConfig: {
      elId: 'cm_vehicle-visualization',
      height: '100px',
      vehicleImageSize: 640,
      vehicleAngles: [32],
      showColorPicker: false,
    },
    vehicleString,
  });
}

const onVehicleDiscarded = () => {
  [...window.document.body.classList]
    .filter((cl) => cl.startsWith('cm_') && (cl.endsWith('-visualized') || cl.endsWith('-failed')))
    .forEach((className) => {
      window.document.body.classList.remove(className);
    });

  cartVerifyOnVehicleDiscarded();
};

const AfterInit = () => {
  cartVerifyAfterInit();
};

const InitFunc = () => {
  shopifyGenericDefaults.InitFunc?.();
  setStoreConfig('2f3d7a-2', [...baseFields, ...extraFields]);

  const searchModalButton = window.document.querySelector(
    '.header-search-modal:not([open]) .dropdown-modal-toggle',
  );
  searchModalButton?.addEventListener('click', () => {
    window.document.querySelector('#cm-search-box .cm_searchInput')?.focus();
  });

  const optionSelectors = window.document.querySelectorAll(
    'body.template-product .ecom-product-single__variant-picker select.single-option-selector',
  );

  optionSelectors?.forEach((element) => {
    element.addEventListener('change', () => {
      setTimeout(() => {
        window.Convermax.updateVerifyFitmentWidget(getItemId());
      }, 300);
    });
  });
};

window.Convermax.handleKlaviyo = (e) => {
  if (window.klaviyo) {
    const product = e.target.closest('form').querySelector('input[name="product_attributes"]');

    if (product) {
      const item = {
        Name: product.dataset.title,
        ProductID: product.dataset.id,
        Categories: product.dataset.category?.split(','),
        ImageURL: product.dataset.image,
        URL: product.dataset.url,
        Brand: product.dataset.vendor,
        Price: product.dataset.price,
        CompareAtPrice: product.dataset.compare_at_price,
      };

      window.klaviyo.push(['track', 'Added to Cart', item]);
    }
  }
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  InitFunc,
  AfterInit,
  SearchRequestDefaults: {
    pageSize: 30,
  },
  pagination: {
    edgeRange: 0,
    centralRange: 2,
    disableDelimeters: true,
  },
  product: {
    ...shopifyGenericDefaults.product,
    getItemId,
  },
  fitmentSearch: {
    ...shopifyGenericDefaults.fitmentSearch,
    categorySelectionPages: [
      ...shopifyGenericDefaults.fitmentSearch.categorySelectionPages,
      {
        pathname: window.document.querySelector('#cm-wheel-tire-brand-list')
          ? window.location.pathname
          : null,
        field: 'vendor',
      },
    ],
    categorySelectionPageRedirect: true,
    onVehicleSelected: cartVerifyOnVehicleSelected,
    onVehicleDiscarded,
    fitmentDataProcessor: (fitmentData) => fitmentData,
    getFitmentSearchTitle,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter(
      (w) =>
        ![
          'SearchResult',
          'SearchBox',
          'FacetBarWheels',
          'FacetBarTires',
          'VerifyFitment',
          'FitmentTable',
          'RelatedItems',
        ].includes(w.name),
    ),
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
    {
      name: 'SearchBox',
      location: { selector: '#cm-search-box', class: 'cm-search-box' },
      onDropdownItemsReceived: shopifyGenericDefaults.Widgets.find((w) => w.name === 'SearchBox')
        .onDropdownItemsReceived,
      template: 'SearchBox',
      onSubmit: () => window.document.querySelector('.header-search-modal .dropdown-modal-toggle')?.click(),
    },
    {
      name: 'FacetBarWheels',
      type: 'FacetBar',
      fields: wheelsFields,
      minFacetCount: 2,
    },
    {
      name: 'FacetBarTires',
      type: 'FacetBar',
      fields: tiresFields,
      minFacetCount: 2,
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-home-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 770,
    },
    {
      name: 'HeaderVehicleWidget_cart',
      type: 'VehicleWidget',
      location: {
        firstChildOf: '.cart-footer .cart-blocks',
      },
      template: 'fitmentSearch/cartVehicleWidget',
      isAlwaysColumnLayout: true,
      isAutoVehicleSelectionDisabled: true,
      doNotRedirectOnVehicleSelect: true,
      initCollapsed: true,
    },
    {
      name: 'WheelPanel_tab',
      type: 'RequestPanel',
      location: '#cm-home-wheels',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: '/collections/wheels',
      selectFields: wheelsFields,
    },
    {
      name: 'TirePanel_tab',
      type: 'RequestPanel',
      location: '#cm-home-tire',
      template: 'HomeWheelTireBar',
      needInitRequest: true,
      redirectUrl: '/collections/tires',
      selectFields: tiresFields,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: '#cm-verify-fitment',
      template: 'fitmentSearch/collapsibleVerifyFitment',
      isAlwaysColumnLayout: true,
      initCollapsed: true,
    },
    {
      name: 'FitmentTable',
      location: '#cm-fitment-table',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'WheelTireVehicleInfo',
    },
    {
      name: 'UpsizeDownsizeFacetPanel',
      type: 'FacetPanel',
      fields: ['wheel_tire_diameter'],
      showAllAlways: true,
      disableCollapse: true,
    },
    {
      name: 'FacetWheelTireBrandTiles',
      type: 'FacetTiles',
      location: {
        selector: '#cm-wheel-tire-brand-list',
        class: 'productgrid--outer',
      },
      template: 'facetTiles/hierarchicalContainerBrands',
    },
    {
      name: 'VehicleWheelDialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/vehicleWheelDialog',
    },
    {
      name: 'VehicleWheelDialogButton',
      type: 'DialogButton',
      location: {
        lastChildOf: 'body',
        class: 'cm_hide',
      },
      template: 'fitmentSearch/customDialogButton',
      dialogName: 'VehicleWheelDialog',
    },
    {
      name: 'RelatedItems',
      location: '#cm-related-products',
      template: 'product/relatedItems',
      count: 4,
      getSelection,
    },
  ],
};
