const isCartPage = window.document.body.classList.contains('template-cart');

const getCartProductIds = () => {
  const productIds = [];

  window.document
    .querySelectorAll('.cart-items .cart-item')
    ?.forEach((item) => productIds.push(item.getAttribute('product-id')));

  return productIds;
};

const getVerifyFitmentLabelHtml = (fits, productType) => {
  return `
    <div class="cm_vehicle-widget cm_vehicle-widget__verify-fitment cm_vehicle-widget__verify-fitment__cart">
      <div class="cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__${fits}">
        <div class="cm_verify-fitment_title">
          This ${productType} ${fits === 'yes' ? 'fits' : 'does not fit'} your vehicle
        </div>
      </div>
    </div>
  `;
};

const hideCartVerifyFitmentLabels = () => {
  const productIds = getCartProductIds();

  if (productIds?.length > 0) {
    productIds.forEach((productId) => {
      window.document.getElementById(`cm_verify-fitment-label-container-${productId}`).style.display = 'none';
    });
  }
};

const updateCartVerifyFitmentLabels = (vehicle) => {
  const productIds = getCartProductIds();

  if (isCartPage && productIds?.length > 0) {
    productIds.forEach((productId) => {
      window.Convermax.verifyFitmentAsync(productId.toString(), vehicle)
        .then((fits) => {
          if (fits !== 'yes' && fits !== 'no') {
            return;
          }

          const containerElem = window.document.getElementById(
            `cm_verify-fitment-label-container-${productId}`,
          );

          if (containerElem) {
            const productType = containerElem.getAttribute('product-type');
            const verifyFitmentLabel = getVerifyFitmentLabelHtml(fits, productType);
            containerElem.innerHTML = verifyFitmentLabel;
            containerElem.style.display = 'block';
          }
        })
        .catch(() => {
          hideCartVerifyFitmentLabels();
        });
    });
  }
};

export const onVehicleDiscarded = () => {
  if (isCartPage) {
    hideCartVerifyFitmentLabels();
  }
};

export const onVehicleSelected = (vehicle) => {
  if (isCartPage) {
    updateCartVerifyFitmentLabels(vehicle);
  }
};

export const AfterInit = () => {
  const vehicle = window.Convermax.getVehicle();

  if (isCartPage && Object.keys(vehicle).length > 0) {
    updateCartVerifyFitmentLabels(vehicle);

    window.document.querySelectorAll('cart-items .cart-item')?.forEach((e) =>
      e.addEventListener('change', () => {
        setTimeout(() => {
          updateCartVerifyFitmentLabels(vehicle);
        }, 1000);
      }),
    );

    window.document.querySelectorAll('cart-remove-button')?.forEach((e) =>
      e.addEventListener('click', () => {
        setTimeout(() => {
          updateCartVerifyFitmentLabels();
        }, 1000);
      }),
    );
  }
};
